import { useMemo } from "react"
import { ChakraProvider, theme } from "@chakra-ui/react"
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Dashboard } from "./components/Dashboard/Dashboard"
import { useCluster } from "./components/ClusterContext/ClusterContext";
import { CLUSTERS } from "./config";

export const App = () => {
  const { selectedCluster } = useCluster();

  const client = useMemo(() => {
    return new ApolloClient({
      uri: `https://api.${CLUSTERS[selectedCluster].host}/query`,
      cache: new InMemoryCache(),
    })
  }, [selectedCluster]);

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <Dashboard />
      </ChakraProvider>
    </ApolloProvider>
  )
}
